<ng-template #userContractButtons>
  <div class="d-flex justify-content-between userContractButtonssDiv">
    <div>
      <button style="margin-right: 5px" class="btn btn-info ng-star-inserted" (click)="selectAll()">
        {{ 'User.Contracts.SelectAll' | translate }}
      </button>
      <button class="btn btn-success ng-star-inserted" (click)="acceptOrConfirmSelected()">
        {{ 'User.Contracts.AcceptOrConfirmSelected' | translate }}
      </button>
    </div>
    <div>
      <button class="btn btn-danger ng-star-inserted" (click)="terminateOrDeclineSelected()">
        {{ 'User.Contracts.TerminateOrDeclineSelected' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="container container-plus">
  <div class="page-header">
    <h1>
      {{ 'User.Contracts.Header' | translate }}
      <span *ngIf="employee">
        {{ 'Global.For' | translate }}
        <span style="font-weight: bold" *ngIf="employee.title"> {{ employee.title }}</span>
        <span style="font-weight: bold" *ngIf="employee.firstName"> {{ employee.firstName }}</span>
        <span style="font-weight: bold" *ngIf="employee.lastName"> {{ employee.lastName }}</span>
      </span>
      <span *ngIf="userProfile">
        {{ 'Global.For' | translate }}
        <span style="font-weight: bold" *ngIf="userProfile.title"> {{ userProfile.title }}</span>
        <span style="font-weight: bold" *ngIf="firstname"> {{ firstname }}</span>
        <span style="font-weight: bold" *ngIf="lastname"> {{ lastname }}</span>
      </span>
    </h1>
  </div>
  <div class="alert alert-warning" [innerHTML]="'User.Contracts.InfoText' | translate"></div>
  <div
    *ngIf="userMustEnterMobile"
    class="alert alert-info"
    [innerHTML]="'User.Contracts.UserMustEnterMobile' | translate | safeHtml"
    routeTransformer></div>
  <div class="filterUserContractsCheckbox">
    <input
      type="checkbox"
      id="filterUserContractsCheckbox"
      [(ngModel)]="showInactiveUserContracts"
      (change)="filterUserContracts()" />
    <label for="filterUserContractsCheckbox">{{ 'User.Contracts.FilterUserContracts' | translate }}</label>
  </div>
  <ng-container *ngTemplateOutlet="userContractButtons"></ng-container>
  <div *ngIf="filteredUserContracts" class="userContractsDiv">
    <mat-selection-list #selectionList [(ngModel)]="selectedOptions" class="mat-list-table mat-list-table-1300">
      <div fxLayout="row" class="contractListHeader">
        <div class="contractName" style="font-weight: bold">
          {{ 'User.Contracts.ContractNameHeader' | translate }}
        </div>
        <div class="contractStatus" style="font-weight: bold">
          {{ 'User.Contracts.ContractStatusHeader' | translate }}
        </div>
        <div class="contractValidFrom" style="font-weight: bold">
          {{ 'User.Contracts.ContractValidFromHeader' | translate }}
        </div>
        <div class="contractValidUntil" style="font-weight: bold">
          {{ 'User.Contracts.ContractValidUntilHeader' | translate }}
        </div>
        <div class="individualFactor" style="font-weight: bold" *ngIf="!appComponent.features.hideCompensationValues">
          {{ 'Global.IndividualFactorShort' | translate }}
        </div>
        <div class="overallFactorP1" style="font-weight: bold" *ngIf="!appComponent.features.hideCompensationValues">
          {{ 'Global.OverallFactorP1' | translate }}
        </div>
        <div class="overallFactorP2" style="font-weight: bold" *ngIf="!appComponent.features.hideCompensationValues">
          {{ 'Global.OverallFactorP2' | translate }}
        </div>
        <div class="compensationType" style="font-weight: bold" *ngIf="!appComponent.features.hideCompensationValues">
          {{ 'Global.CompensationType' | translate }}
        </div>
        <div class="contractContent" style="font-weight: bold">
          {{ 'Global.ContractContent' | translate }}
        </div>
        <div class="contractContactInformation" style="font-weight: bold">
          {{ 'Global.ContractContactInformationHeader' | translate }}
        </div>
      </div>
      <ng-container *ngFor="let userContract of filteredUserContracts">
        <mat-list-option
          [ngClass]="{ 'hide-checkbox': !showCheckbox(userContract) }"
          [value]="userContract"
          [disabled]="!showCheckbox(userContract)"
          checkboxPosition="before">
          <div class="contractListRow">
            <div class="contractName">
              <span>{{ contractTypes[userContract.contractId].title }}</span>
              <div *ngIf="contractTypes[userContract.contractId].catalogName">
                <span
                  >{{ 'Global.Catalog' | translate }}: {{ contractTypes[userContract.contractId].catalogName }}</span
                >
              </div>
            </div>
            <div class="contractStatus">
              <span>
                {{
                  userContract.contractStatus
                    | contractStatusTransform
                    | translate
                      : {
                          terminatedDate: userContract.terminatedDate | date: 'dd.MM.yyyy',
                          terminatedBy:
                            userContract.terminationType | contractTerminationTypeTransform: 'InSentence' | translate
                        }
                }}
              </span>
              <span *ngIf="userContract.terminationType == contractTerminationType.AutomaticallyTerminated">
                {{
                  'Contract.Status.ContractWasValidUntil'
                    | translate
                      : {
                          validUntilDate: userContract.validUntil | date: 'dd.MM.yyyy'
                        }
                }}
              </span>
            </div>
            <div class="contractValidFrom">
              <span *ngIf="showContractDetails(userContract)">{{
                userContract.validFrom | contractDateTransform: 'dd.MM.yyyy'
              }}</span>
            </div>
            <div class="contractValidUntil">
              <span *ngIf="showContractDetails(userContract)">{{
                userContract.validUntil | contractDateTransform: 'dd.MM.yyyy'
              }}</span>
            </div>
            <div class="individualFactor" *ngIf="!appComponent.features.hideCompensationValues">
              <span *ngIf="showContractDetails(userContract)">{{
                userContract.individualCompensation?.value | number: '1.2-2'
              }}</span>
            </div>
            <div class="overallFactorP1" *ngIf="!appComponent.features.hideCompensationValues">
              <span *ngIf="showContractDetails(userContract)">{{
                userContract.overallFactorP1 | number: '1.2-3'
              }}</span>
            </div>
            <div class="overallFactorP2" *ngIf="!appComponent.features.hideCompensationValues">
              <span *ngIf="showContractDetails(userContract)">{{
                userContract.overallFactorP2 | number: '1.2-3'
              }}</span>
            </div>
            <div class="compensationType" *ngIf="!appComponent.features.hideCompensationValues">
              <span *ngIf="showContractDetails(userContract)">{{
                contractTypes[userContract.contractId].compensationType | contractCompensationTypeTransform | translate
              }}</span>
            </div>
            <div class="contractContent" style="overflow-wrap: break-word">
              <div>
                <a
                  href="#"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                  openPdf="{{ 'api/file/' + getContractType(userContract.contractId)?.pdf }}"
                  target="_blank"
                  >{{ 'Contract.Pdf.PdfTitle' | translate }}</a
                >
              </div>
              <div *ngIf="getContractType(userContract.contractId)?.pdf02">
                <a
                  href="#"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                  openPdf="{{ 'api/file/' + getContractType(userContract.contractId)?.pdf02 }}"
                  target="_blank"
                  >{{ 'Contract.Pdf.Pdf02Title' | translate }}</a
                >
              </div>
              <div *ngIf="getContractType(userContract.contractId)?.pdf03">
                <a
                  href="#"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                  openPdf="{{ 'api/file/' + getContractType(userContract.contractId)?.pdf03 }}"
                  target="_blank"
                  >{{ 'Contract.Pdf.Pdf03Title' | translate }}</a
                >
              </div>
            </div>
            <div class="contractContactInformation">
              <button
                *ngIf="contractTypes[userContract.contractId].address"
                ngClass="btn mb-1"
                style="flex: 0 0 35px; padding: unset; margin: 0px"
                (click)="openContactDetails(contractTypes[userContract.contractId]); $event.stopPropagation()"
                [tooltip]="'Global.ContractContactInformationTooltip' | translate"
                [delay]="500"
                container="body"
                containerClass="tooltipClass">
                <div ngClass="blue fa fa-address-book fa-lg"></div>
              </button>
            </div>
          </div>
        </mat-list-option>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
    <ng-container *ngTemplateOutlet="userContractButtons"></ng-container>
  </div>
</div>
