import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';

import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { appConfig } from 'config/appConfig';
import { Subscription } from 'rxjs';
import { loadTranslations } from '../i18n/translations';
import { NgAceSidebar } from './_ace/directives/ace-sidebar.directive';
import { TextDialogComponent } from './_dialogs/textdialog/textdialog.component';
import { FeatureFlags } from './_models/applicationConfigDto';
import { AccountService } from './_services/account.service';
import { ApplicationService } from './_services/application.service';
import { RefreshableView } from './_views/refreshable-view';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebar', { static: true }) sidebar!: NgAceSidebar;

  public static pageSizeOptions = [25, 50, 100, 200];
  public static features: FeatureFlags = {
    simplifiedRegistration: false,
    patientCatalogSearch: false,
    hideCompensationValues: false,
  };

  public hideNavbar: boolean = false;
  public hideSidebar: boolean = false;

  private currentComponent?: Component;
  private previousUrl?: string;
  private routerSubscription?: Subscription;

  public footerImage = appConfig.footerImageUrl;

  constructor(
    public translate: TranslateService,
    public accountService: AccountService,
    private applicationService: ApplicationService,
    private router: Router,
    private location: Location,
    private swUpdate: SwUpdate,
    public modalService: BsModalService,
    private titleService: Title,
    private oauthService: OAuthService,
  ) {
    loadTranslations(this.translate);

    var userLanguage = localStorage.getItem('userLanguage');

    if (!userLanguage) {
      userLanguage = this.getSupportedBrowserLanguage();
    }

    this.accountService.setUserLanguage(userLanguage);
  }

  public getSupportedBrowserLanguage(): string {
    var supportedBrowserLanguage = undefined;
    var supportedBrowserLanguages = this.translate.getLangs();

    for (var browserLanguage of window.navigator.languages) {
      supportedBrowserLanguage = supportedBrowserLanguages.find(
        (x) => browserLanguage && x.startsWith(browserLanguage),
      );

      if (supportedBrowserLanguage) {
        return supportedBrowserLanguage;
      }
    }

    return 'de-CH';
  }

  ngOnInit() {
    this.setCurrentUser();

    this.applicationService.getApplicationConfig().subscribe((result) => {
      AppComponent.pageSizeOptions = result.pageSizeOptions;
      AppComponent.features = result.features;

      if (result.sentryDsn) {
        Sentry.init({
          dsn: result.sentryDsn,
          environment: result.sentryEnvironment,
          ignoreErrors: ['401 Unauthorized'],
        });
      }

      // initialize oAuth service
      this.oauthService.configure({
        clientId: 'core-' + result.flavor,
        redirectUri: window.location.origin,
        issuer: result.oauthIssuerUrl,
        responseType: 'code',
        scope: 'openid profile email',
        tokenEndpoint: result.oauthIssuerUrl + '/protocol/openid-connect/token',
      });

      this.oauthService.setupAutomaticSilentRefresh();
      this.oauthService.loadDiscoveryDocumentAndTryLogin();

      // check if user logged out on other tab
      window.addEventListener('storage', (event) => {
        if (event.storageArea == localStorage && !this.oauthService.hasValidAccessToken()) {
          this.accountService.logout();
        }
      });
    });

    // Subscribe to application status to lockout users if applicaiton is locked.
    this.applicationService.subscribeApplicationStatus();

    // Subscribe to application updates and prompt user to reload the page.
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        console.log('current version: ', event.current);
        console.log('available version: ', event.available);

        TextDialogComponent.showDialogTranslated(
          this.translate,
          this.modalService,
          'Global.ApplicationUpdateAvailableTitle',
          'Global.ApplicationUpdateAvailable',
          'Global.Ok',
          'Global.Cancel',
        ).then((modalRef) => {
          modalRef.content?.onClose.subscribe((onCloseResult) => {
            if (onCloseResult) {
              this.swUpdate.activateUpdate().then(() => {
                window.location.reload();
              });
            }
          });
        });
      });
    }

    // Subscribe to router events so we can reload components when the same routerlink is clicked again.
    this.routerSubscription = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((navigationEnd: NavigationEnd) => {
        if (this.instanceOfRefreshableView(this.currentComponent) && this.previousUrl == navigationEnd.url) {
          this.currentComponent.refreshView();
        }

        this.previousUrl = navigationEnd.url;
      });

    this.translate.get('Nav.Title').subscribe((title) => {
      this.titleService.setTitle(title);
    });
  }

  instanceOfRefreshableView(view: any): view is RefreshableView {
    if (view) {
      return 'refreshView' in view;
    }

    return false;
  }

  ngAfterViewInit() {
    // The is-document-loaded class is needed for Ace to enable transitions.
    document.body.classList.add('is-document-loaded');
  }

  ngOnDestroy() {
    this.applicationService.unsubscribeApplicationStatus();

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public setCurrentComponent(componentRef: Component) {
    this.currentComponent = componentRef;
  }

  setCurrentUser() {
    // Save the current url location so we can navigate back in case we can get a new access token.
    const currentUrl = this.location.path();
    if (this.oauthService.hasValidAccessToken()) {
      this.accountService.setUsernameFromToken();
      this.accountService.updateUserRoles();
      this.accountService.loadUserLanguage();
      this.redirectFromLogin(currentUrl, true);
    }
  }

  /**
   * This function decides if the user should be redirected to the dashboard based on the current url.
   * @param url Current url
   * @param navigateToUrl If set to true and the user is not redirected to the dashboard, this will redirect to the url from the url parameter.
   */
  redirectFromLogin(url: string, navigateToUrl: boolean) {
    if (url == '' || url.startsWith('/account/login')) {
      this.router.navigate(['/home/dashboard']);
    } else if (navigateToUrl) {
      this.router.navigateByUrl(url);
    }
  }
}
